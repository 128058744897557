export function getApiRoute(route = '') {
    const origin = window.location.origin;

    if (origin.includes('.test')) {
        return `https://ggdafspraak-post.test/api/${route}`;
    }

    if (origin.includes('dev.ggdafspraak.nl')) {
        return `https://forms.dev.ggdafspraak.nl/api/${route}`;
    }

    if (origin.includes('acc.ggdafspraak.nl')) {
        return `https://forms.acc.ggdafspraak.nl/api/${route}`;
    }

    return `https://forms.ggdafspraak.nl/api/${route}`;
}
