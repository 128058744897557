<template>
	<legend
		v-if="context.name === 'terms' && context.classification === 'group'"
		:id="`${context.id}_label`"
		:class="context.classes.label"
		:for="context.id"
	>
		{{ context.label }} <sup v-if="isRequired" class="required">*</sup>
	</legend>
	<label v-else :id="`${context.id}_label`" :class="context.classes.label" :for="context.id">
		<template v-if="context.name === 'terms' && context.classification === 'box'">
			{{ translations.agreement_statement_option_one }}
			<a
				:href="translations.agreement_statement_option_address"
				target="_blank"
				rel="noopener noreferrer nofollow"
				:title="translations.agreement_statement_option_title"
				>{{ translations.agreement_statement_option_text }}</a
			>{{ translations.agreement_statement_option_two }}
		</template>
		<template v-else><span v-html="context.label"></span> <sup v-if="isRequired" class="required">*</sup></template>
	</label>
</template>

<script>
export default {
	props: {
		context: {
			type: Object,
			required: true,
		},
	},
	computed: {
		translations() {
			const currentLanguage = document
				.getElementsByTagName('html')[0]
				.getAttribute('lang')
				.substring(0, 2);

			return currentLanguage === 'en' ? WP.en : WP.nl;
		},
		isRequired() {
			return this.context.rules.findIndex(rule => rule.ruleName === 'required') > -1;
		},
	},
};
</script>
