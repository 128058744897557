<template>
	<div v-if="context.visibleValidationErrors.length" class="input-errors" role="alert">
		<FormulateErrors :context="context" />
	</div>
</template>

<script>
export default {
	props: {
		context: {
			type: Object,
			default: () => ({}),
		},
	},
};
</script>
