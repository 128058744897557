// Each country array has the following information:
// [
//    Country name,
//    iso2 code,
//    International dial code
// ]
const allCountriesEN = [
	['Afghanistan', 'af', '93'],
	['Albania', 'al', '355'],
	['Algeria', 'dz', '213'],
	['American Samoa', 'as', '1684'],
	['Andorra', 'ad', '376'],
	['Angola', 'ao', '244'],
	['Anguilla', 'ai', '1264'],
	['Antigua and Barbuda', 'ag', '1268'],
	['Argentina', 'ar', '54'],
	['Armenia', 'am', '374'],
	['Aruba', 'aw', '297'],
	['Australia', 'au', '61'],
	['Austria', 'at', '43'],
	['Azerbaijan', 'az', '994'],
	['Bahamas', 'bs', '1242'],
	['Bahrain', 'bh', '973'],
	['Bangladesh', 'bd', '880'],
	['Barbados', 'bb', '1246'],
	['Belarus', 'by', '375'],
	['Belgium', 'be', '32'],
	['Belize', 'bz', '501'],
	['Benin', 'bj', '229'],
	['Bermuda', 'bm', '1441'],
	['Bhutan', 'bt', '975'],
	['Bolivia', 'bo', '591'],
	['Bosnia and Herzegovina', 'ba', '387'],
	['Botswana', 'bw', '267'],
	['Brazil', 'br', '55'],
	['British Indian Ocean Territory', 'io', '246'],
	['British Virgin Islands', 'vg', '1284'],
	['Brunei', 'bn', '673'],
	['Bulgaria', 'bg', '359'],
	['Burkina Faso', 'bf', '226'],
	['Burundi', 'bi', '257'],
	['Cambodia', 'kh', '855'],
	['Cameroon', 'cm', '237'],
	['Canada', 'ca', '1'],
	['Cape Verde', 'cv', '238'],
	['Caribbean Netherlands', 'bq', '599'],
	['Cayman Islands', 'ky', '1345'],
	['Central African Republic', 'cf', '236'],
	['Chad', 'td', '235'],
	['Chile', 'cl', '56'],
	['China', 'cn', '86'],
	['Christmas Island', 'cx', '61'],
	['Cocos Islands', 'cc', '61'],
	['Colombia', 'co', '57'],
	['Comoros', 'km', '269'],
	['Congo', 'cd', '243'],
	['Congo', 'cg', '242'],
	['Cook Islands', 'ck', '682'],
	['Costa Rica', 'cr', '506'],
	['Côte d’Ivoire', 'ci', '225'],
	['Croatia', 'hr', '385'],
	['Cuba', 'cu', '53'],
	['Curaçao', 'cw', '599'],
	['Cyprus', 'cy', '357'],
	['Czech Republic', 'cz', '420'],
	['Denmark', 'dk', '45'],
	['Djibouti', 'dj', '253'],
	['Dominica', 'dm', '1767'],
	['Dominican Republic', 'do', '1'],
	['Ecuador', 'ec', '593'],
	['Egypt', 'eg', '20'],
	['El Salvador', 'sv', '503'],
	['Equatorial Guinea', 'gq', '240'],
	['Eritrea', 'er', '291'],
	['Estonia', 'ee', '372'],
	['Ethiopia', 'et', '251'],
	['Falkland Islands', 'fk', '500'],
	['Faroe Islands', 'fo', '298'],
	['Fiji', 'fj', '679'],
	['Finland', 'fi', '358'],
	['France', 'fr', '33'],
	['French Guiana', 'gf', '594'],
	['French Polynesia', 'pf', '689'],
	['Gabon', 'ga', '241'],
	['Gambia', 'gm', '220'],
	['Georgia', 'ge', '995'],
	['Germany', 'de', '49'],
	['Ghana', 'gh', '233'],
	['Gibraltar', 'gi', '350'],
	['Greece', 'gr', '30'],
	['Greenland', 'gl', '299'],
	['Grenada', 'gd', '1473'],
	['Guadeloupe', 'gp', '590'],
	['Guam', 'gu', '1671'],
	['Guatemala', 'gt', '502'],
	['Guernsey', 'gg', '44', 1],
	['Guinea', 'gn', '224'],
	['Guinea-Bissau', 'gw', '245'],
	['Guyana', 'gy', '592'],
	['Haiti', 'ht', '509'],
	['Honduras', 'hn', '504'],
	['Hong Kong', 'hk', '852'],
	['Hungary', 'hu', '36'],
	['Iceland', 'is', '354'],
	['India', 'in', '91'],
	['Indonesia', 'id', '62'],
	['Iran', 'ir', '98'],
	['Iraq', 'iq', '964'],
	['Ireland', 'ie', '353'],
	['Isle of Man', 'im', '44'],
	['Israel', 'il', '972'],
	['Italy', 'it', '39'],
	['Jamaica', 'jm', '1876'],
	['Japan', 'jp', '81'],
	['Jersey', 'je', '44'],
	['Jordan', 'jo', '962'],
	['Kazakhstan', 'kz', '7'],
	['Kenya', 'ke', '254'],
	['Kiribati', 'ki', '686'],
	['Kosovo', 'xk', '383'],
	['Kuwait', 'kw', '965'],
	['Kyrgyzstan', 'kg', '996'],
	['Laos', 'la', '856'],
	['Latvia', 'lv', '371'],
	['Lebanon', 'lb', '961'],
	['Lesotho', 'ls', '266'],
	['Liberia', 'lr', '231'],
	['Libya', 'ly', '218'],
	['Liechtenstein', 'li', '423'],
	['Lithuania', 'lt', '370'],
	['Luxembourg', 'lu', '352'],
	['Macau', 'mo', '853'],
	['Macedonia', 'mk', '389'],
	['Madagascar', 'mg', '261'],
	['Malawi', 'mw', '265'],
	['Malaysia', 'my', '60'],
	['Maldives', 'mv', '960'],
	['Mali', 'ml', '223'],
	['Malta', 'mt', '356'],
	['Marshall Islands', 'mh', '692'],
	['Martinique', 'mq', '596'],
	['Mauritania', 'mr', '222'],
	['Mauritius', 'mu', '230'],
	['Mayotte', 'yt', '262'],
	['Mexico', 'mx', '52'],
	['Micronesia', 'fm', '691'],
	['Moldova', 'md', '373'],
	['Monaco', 'mc', '377'],
	['Mongolia', 'mn', '976'],
	['Montenegro', 'me', '382'],
	['Montserrat', 'ms', '1664'],
	['Morocco', 'ma', '212'],
	['Mozambique', 'mz', '258'],
	['Myanmar', 'mm', '95'],
	['Namibia', 'na', '264'],
	['Nauru', 'nr', '674'],
	['Nepal', 'np', '977'],
	['Netherlands', 'nl', '31'],
	['New Caledonia', 'nc', '687'],
	['New Zealand', 'nz', '64'],
	['Nicaragua', 'ni', '505'],
	['Niger', 'ne', '227'],
	['Nigeria', 'ng', '234'],
	['Niue', 'nu', '683'],
	['Norfolk Island', 'nf', '672'],
	['North Korea', 'kp', '850'],
	['Northern Mariana Islands', 'mp', '1670'],
	['Norway', 'no', '47'],
	['Oman', 'om', '968'],
	['Pakistan', 'pk', '92'],
	['Palau', 'pw', '680'],
	['Palestine', 'ps', '970'],
	['Panama', 'pa', '507'],
	['Papua New Guinea', 'pg', '675'],
	['Paraguay', 'py', '595'],
	['Peru', 'pe', '51'],
	['Philippines', 'ph', '63'],
	['Poland', 'pl', '48'],
	['Portugal', 'pt', '351'],
	['Puerto Rico', 'pr', '1'],
	['Qatar', 'qa', '974'],
	['Réunion', 're', '262'],
	['Romania', 'ro', '40'],
	['Russia', 'ru', '7'],
	['Rwanda', 'rw', '250'],
	['Saint Barthélemy', 'bl', '590'],
	['Saint Helena', 'sh', '290'],
	['Saint Kitts and Nevis', 'kn', '1869'],
	['Saint Lucia', 'lc', '1758'],
	['Saint Martin', 'mf', '590'],
	['Saint Pierre and Miquelon', 'pm', '508'],
	['Saint Vincent and the Grenadines', 'vc', '1784'],
	['Samoa', 'ws', '685'],
	['San Marino', 'sm', '378'],
	['São Tomé and Príncipe', 'st', '239'],
	['Saudi Arabia', 'sa', '966'],
	['Senegal', 'sn', '221'],
	['Serbia', 'rs', '381'],
	['Seychelles', 'sc', '248'],
	['Sierra Leone', 'sl', '232'],
	['Singapore', 'sg', '65'],
	['Sint Maarten', 'sx', '1721'],
	['Slovakia', 'sk', '421'],
	['Slovenia', 'si', '386'],
	['Solomon Islands', 'sb', '677'],
	['Somalia', 'so', '252'],
	['South Africa', 'za', '27'],
	['South Korea', 'kr', '82'],
	['South Sudan', 'ss', '211'],
	['Spain', 'es', '34'],
	['Sri Lanka', 'lk', '94'],
	['Sudan', 'sd', '249'],
	['Suriname', 'sr', '597'],
	['Svalbard and Jan Mayen', 'sj', '47'],
	['Swaziland', 'sz', '268'],
	['Sweden', 'se', '46'],
	['Switzerland', 'ch', '41'],
	['Syria', 'sy', '963'],
	['Taiwan', 'tw', '886'],
	['Tajikistan', 'tj', '992'],
	['Tanzania', 'tz', '255'],
	['Thailand', 'th', '66'],
	['Timor-Leste', 'tl', '670'],
	['Togo', 'tg', '228'],
	['Tokelau', 'tk', '690'],
	['Tonga', 'to', '676'],
	['Trinidad and Tobago', 'tt', '1868'],
	['Tunisia', 'tn', '216'],
	['Turkey', 'tr', '90'],
	['Turkmenistan', 'tm', '993'],
	['Turks and Caicos Islands', 'tc', '1649'],
	['Tuvalu', 'tv', '688'],
	['U.S. Virgin Islands', 'vi', '1340'],
	['Uganda', 'ug', '256'],
	['Ukraine', 'ua', '380'],
	['United Arab Emirates', 'ae', '971'],
	['United Kingdom', 'gb', '44'],
	['United States', 'us', '1'],
	['Uruguay', 'uy', '598'],
	['Uzbekistan', 'uz', '998'],
	['Vanuatu', 'vu', '678'],
	['Vatican City', 'va', '39'],
	['Venezuela', 've', '58'],
	['Vietnam', 'vn', '84'],
	['Wallis and Futuna', 'wf', '681'],
	['Western Sahara', 'eh', '212'],
	['Yemen', 'ye', '967'],
	['Zambia', 'zm', '260'],
	['Zimbabwe', 'zw', '263'],
	['Åland Islands', 'ax', '358'],
];

const allCountriesNL = [
	['Afghanistan', 'af', '93'],
	['Albanië', 'al', '355'],
	['Algerije', 'dz', '213'],
	['Amerikaans Samoa', 'as', '1684'],
	['Andorra', 'ad', '376'],
	['Angola', 'ao', '244'],
	['Anguilla', 'ai', '1264'],
	['Antigua en Barbuda', 'ag', '1268'],
	['Argentinië', 'ar', '54'],
	['Armenië', 'am', '374'],
	['Aruba', 'aw', '297'],
	['Australië', 'au', '61'],
	['Oostenrijk', 'at', '43'],
	['Azerbeidzjan', 'az', '994'],
	["Bahama's", 'bs', '1242'],
	['Bahrein', 'bh', '973'],
	['Bangladesh', 'bd', '880'],
	['Barbados', 'bb', '1246'],
	['Belarus', 'by', '375'],
	['België', 'be', '32'],
	['Belize', 'bz', '501'],
	['Benin', 'bj', '229'],
	['Bermuda', 'bm', '1441'],
	['Bhutan', 'bt', '975'],
	['Bolivia', 'bo', '591'],
	['Bosnië en Herzegovina', 'ba', '387'],
	['Botswana', 'bw', '267'],
	['Brazilië', 'br', '55'],
	['Brits Territorium in de Indische Oceaan', 'io', '246'],
	['Britse Maagdeneilanden', 'vg', '1284'],
	['Brunei', 'bn', '673'],
	['Bulgarije', 'bg', '359'],
	['Burkina Faso', 'bf', '226'],
	['Burundi', 'bi', '257'],
	['Cambodja', 'kh', '855'],
	['Kameroen', 'cm', '237'],
	['Canada', 'ca', '1'],
	['Kaapverdië', 'cv', '238'],
	['Caribisch Nederland', 'bq', '599'],
	['Kaaimaneilanden', 'ky', '1345'],
	['Centraal-Afrikaanse Republiek', 'cf', '236'],
	['Tsjaad', 'td', '235'],
	['Chili', 'cl', '56'],
	['China', 'cn', '86'],
	['Kersteiland', 'cx', '61'],
	['Cocoseilanden', 'cc', '61'],
	['Colombia', 'co', '57'],
	['Comoren', 'km', '269'],
	['Democratische Republiek Congo', 'cd', '243'],
	['Republiek Congo', 'cg', '242'],
	['Cookeilanden', 'ck', '682'],
	['Costa Rica', 'cr', '506'],
	['Ivoorkust', 'ci', '225'],
	['Kroatië', 'hr', '385'],
	['Cuba', 'cu', '53'],
	['Curaçao', 'cw', '599'],
	['Cyprus', 'cy', '357'],
	['Tsjechië', 'cz', '420'],
	['Denemarken', 'dk', '45'],
	['Djibouti', 'dj', '253'],
	['Dominica', 'dm', '1767'],
	['Dominicaanse Republiek', 'do', '1'],
	['Ecuador', 'ec', '593'],
	['Egypte', 'eg', '20'],
	['El Salvador', 'sv', '503'],
	['Equatoriaal-Guinea', 'gq', '240'],
	['Eritrea', 'er', '291'],
	['Estland', 'ee', '372'],
	['Ethiopië', 'et', '251'],
	['Falklandeilanden', 'fk', '500'],
	['Faeröer', 'fo', '298'],
	['Fiji', 'fj', '679'],
	['Finland', 'fi', '358'],
	['Frankrijk', 'fr', '33'],
	['Frans Guyana', 'gf', '594'],
	['Frans Polynesië', 'pf', '689'],
	['Gabon', 'ga', '241'],
	['Gambia', 'gm', '220'],
	['Georgië', 'ge', '995'],
	['Duitsland', 'de', '49'],
	['Ghana', 'gh', '233'],
	['Gibraltar', 'gi', '350'],
	['Griekenland', 'gr', '30'],
	['Groenland', 'gl', '299'],
	['Grenada', 'gd', '1473'],
	['Guadeloupe', 'gp', '590'],
	['Guam', 'gu', '1671'],
	['Guatemala', 'gt', '502'],
	['Guernsey', 'gg', '44', 1],
	['Guinee', 'gn', '224'],
	['Guinee-Bissau', 'gw', '245'],
	['Guyana', 'gy', '592'],
	['Haïti', 'ht', '509'],
	['Honduras', 'hn', '504'],
	['Hongkong', 'hk', '852'],
	['Hongarije', 'hu', '36'],
	['IJsland', 'is', '354'],
	['India', 'in', '91'],
	['Indonesië', 'id', '62'],
	['Iran', 'ir', '98'],
	['Irak', 'iq', '964'],
	['Ierland', 'ie', '353'],
	['Isle of Man', 'im', '44'],
	['Israël', 'il', '972'],
	['Italië', 'it', '39'],
	['Jamaica', 'jm', '1876'],
	['Japan', 'jp', '81'],
	['Jersey', 'je', '44'],
	['Jordanië', 'jo', '962'],
	['Kazachstan', 'kz', '7'],
	['Kenia', 'ke', '254'],
	['Kiribati', 'ki', '686'],
	['Kosovo', 'xk', '383'],
	['Koeweit', 'kw', '965'],
	['Kirgizië', 'kg', '996'],
	['Laos', 'la', '856'],
	['Letland', 'lv', '371'],
	['Libanon', 'lb', '961'],
	['Lesotho', 'ls', '266'],
	['Liberia', 'lr', '231'],
	['Libië', 'ly', '218'],
	['Liechtenstein', 'li', '423'],
	['Litouwen', 'lt', '370'],
	['Luxemburg', 'lu', '352'],
	['Macau', 'mo', '853'],
	['Noord-Macedonië', 'mk', '389'],
	['Madagascar', 'mg', '261'],
	['Malawi', 'mw', '265'],
	['Maleisië', 'my', '60'],
	['Malediven', 'mv', '960'],
	['Mali', 'ml', '223'],
	['Malta', 'mt', '356'],
	['Marshalleilanden', 'mh', '692'],
	['Martinique', 'mq', '596'],
	['Mauritanië', 'mr', '222'],
	['Mauritius', 'mu', '230'],
	['Mayotte', 'yt', '262'],
	['Mexico', 'mx', '52'],
	['Micronesië', 'fm', '691'],
	['Moldavië', 'md', '373'],
	['Monaco', 'mc', '377'],
	['Mongolië', 'mn', '976'],
	['Montenegro', 'me', '382'],
	['Montserrat', 'ms', '1664'],
	['Marokko', 'ma', '212'],
	['Mozambique', 'mz', '258'],
	['Myanmar', 'mm', '95'],
	['Namibië', 'na', '264'],
	['Nauru', 'nr', '674'],
	['Nepal', 'np', '977'],
	['Nederland', 'nl', '31'],
	['Nieuw-Caledonië', 'nc', '687'],
	['Nieuw-Zeeland', 'nz', '64'],
	['Nicaragua', 'ni', '505'],
	['Niger', 'ne', '227'],
	['Nigeria', 'ng', '234'],
	['Niue', 'nu', '683'],
	['Norfolkeiland', 'nf', '672'],
	['Noord-Korea', 'kp', '850'],
	['Noordelijke Marianen', 'mp', '1670'],
	['Noorwegen', 'no', '47'],
	['Oman', 'om', '968'],
	['Pakistan', 'pk', '92'],
	['Palau', 'pw', '680'],
	['Palestina', 'ps', '970'],
	['Panama', 'pa', '507'],
	['Papoea-Nieuw-Guinea', 'pg', '675'],
	['Paraguay', 'py', '595'],
	['Peru', 'pe', '51'],
	['Filipijnen', 'ph', '63'],
	['Polen', 'pl', '48'],
	['Portugal', 'pt', '351'],
	['Puerto Rico', 'pr', '1'],
	['Qatar', 'qa', '974'],
	['Réunion', 're', '262'],
	['Roemenië', 'ro', '40'],
	['Rusland', 'ru', '7'],
	['Rwanda', 'rw', '250'],
	['Saint-Barthélemy', 'bl', '590'],
	['Sint-Helena', 'sh', '290'],
	['Saint Kitts en Nevis', 'kn', '1869'],
	['Sint-Lucia', 'lc', '1758'],
	['Sint Maarten', 'mf', '590'],
	['Saint Pierre en Miquelon', 'pm', '508'],
	['Saint Vincent en de Grenadines', 'vc', '1784'],
	['Samoa', 'ws', '685'],
	['San Marino', 'sm', '378'],
	['São Tomé en Príncipe', 'st', '239'],
	['Saoedi-Arabië', 'sa', '966'],
	['Senegal', 'sn', '221'],
	['Servië', 'rs', '381'],
	['Seychellen', 'sc', '248'],
	['Sierra Leone', 'sl', '232'],
	['Singapore', 'sg', '65'],
	['Sint Maarten', 'sx', '1721'],
	['Slowakije', 'sk', '421'],
	['Slovenië', 'si', '386'],
	['Salomonseilanden', 'sb', '677'],
	['Somalië', 'so', '252'],
	['Zuid-Afrika', 'za', '27'],
	['Zuid-Korea', 'kr', '82'],
	['Zuid-Soedan', 'ss', '211'],
	['Spanje', 'es', '34'],
	['Sri Lanka', 'lk', '94'],
	['Soedan', 'sd', '249'],
	['Suriname', 'sr', '597'],
	['Svalbard en Jan Mayen', 'sj', '47'],
	['Eswatini', 'sz', '268'],
	['Zweden', 'se', '46'],
	['Zwitzerland', 'ch', '41'],
	['Syrië', 'sy', '963'],
	['Taiwan', 'tw', '886'],
	['Tadzjikistan', 'tj', '992'],
	['Tanzania', 'tz', '255'],
	['Thailand', 'th', '66'],
	['Oost-Timor', 'tl', '670'],
	['Togo', 'tg', '228'],
	['Tokelau', 'tk', '690'],
	['Tonga', 'to', '676'],
	['Trinidad en Tobago', 'tt', '1868'],
	['Tunesië', 'tn', '216'],
	['Turkije', 'tr', '90'],
	['Turkmenistan', 'tm', '993'],
	['Turks- en Caicoseilanden', 'tc', '1649'],
	['Tuvalu', 'tv', '688'],
	['Amerikaanse Maagdeneilanden', 'vi', '1340'],
	['Uganda', 'ug', '256'],
	['Oekraïne', 'ua', '380'],
	['Verenigde Arabische Emiraten', 'ae', '971'],
	['Verenigd Koninkrijk', 'gb', '44'],
	['Verenigde Staten', 'us', '1'],
	['Uruguay', 'uy', '598'],
	['Oezbekistan', 'uz', '998'],
	['Vanuatu', 'vu', '678'],
	['Vaticaanstad', 'va', '39'],
	['Venezuela', 've', '58'],
	['Vietnam', 'vn', '84'],
	['Wallis en Futuna', 'wf', '681'],
	['Westelijke Sahara', 'eh', '212'],
	['Jemen', 'ye', '967'],
	['Zambia', 'zm', '260'],
	['Zimbabwe', 'zw', '263'],
	['Ålandeilanden', 'ax', '358'],
];

const locale = document
	.getElementsByTagName('html')[0]
	.getAttribute('lang')
	.substring(0, 2);
const countries = locale === 'nl' ? allCountriesNL : allCountriesEN;

export default countries
	.map(([name, iso2, dialCode]) => ({
		name,
		iso2: iso2.toUpperCase(),
		dialCode,
	}))
	.sort((a, b) => a['name'].localeCompare(b['name']));
