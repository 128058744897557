// import { createApp } from 'vue';
// import { createPinia } from 'pinia';
// import VueGoogleMaps from '@fawmi/vue-google-maps';
// import SignUpForm from './src/components/SignUpForm.vue';
// import BookingTable from './src/components/BookingTable.vue';
// import LocationBookings from './src/components/LocationBookings.vue';
// import { installI18n } from './src/helpers/i18n';

// // Log errors users receive.
// import * as Sentry from '@sentry/vue';

// if (typeof vueComponents !== 'undefined') {
//     // Make sure media queries are available in Vue components.
//     Foundation.MediaQuery._init();

//     // Create store.
//     const pinia = createPinia();

//     // Loop through each registered card slider and initialize them.
//     vueComponents.forEach(({ type, container, props }) => {
//         let app;
//         if (type === 'booking-table') {
//             app = createApp(BookingTable, props);
//         }
//         if (type === 'location-bookings') {
//             app = createApp(LocationBookings, props);
//         }
//         if (type === 'sign-up-form') {
//             app = createApp(SignUpForm, props);
//         }

//         if (typeof app !== 'undefined') {
//             app.provide('WPAPI', { ...window.WPAPI, ...{ isLargeCustomer: window.WPAPI.isLargeCustomer === 'true' } });
//             app.use(VueGoogleMaps, {
//                 load: false,
//             });
//             app.use(pinia);
//             const i18n = installI18n(app);

//             Sentry.init({
//                 app,
//                 dsn: 'https://7869f37088784830b0cd5b5733e21b2d@sentry.io/1202328',
//                 integrations: [new Sentry.BrowserTracing()],
//                 tracesSampleRate: 0.2,
//             });

//             app.mount(container);
//         }
//     });
// }

// const interval = setInterval(() => {
//     if (typeof google !== 'undefined' && 'vueGoogleMapsInit' in window) {
//         window.vueGoogleMapsInit(google);
//         console.log('Google Maps loaded!');
//         clearInterval(interval);
//     }
// }, 1000);

import Vue from 'vue';
import VueFormulate from '@braid/vue-formulate';
import { nl, en } from '@braid/vue-formulate-i18n';
import axios from 'axios';
import VueAxios from 'vue-axios';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

import FormButton from './src/components/FormButton.vue';
import FormErrors from './src/components/FormErrors.vue';
import FormHelp from './src/components/FormHelp.vue';
import FormLabel from './src/components/FormLabel.vue';
import FormInputTelephone from './src/components/FormInputTelephone.vue';
import Form from './src/Form.vue';
import FormObjection from './src/FormObjection.vue';

// Globally register your component with Vue.
Vue.component('FormButton', FormButton);
Vue.component('FormErrors', FormErrors);
Vue.component('FormHelp', FormHelp);
Vue.component('FormLabel', FormLabel);
Vue.component('FormInputTelephone', FormInputTelephone);

dayjs.extend(isBetween);
Vue.prototype.$date = dayjs;

Vue.use(VueFormulate, {
	useInputDecorators: false,
	plugins: [nl, en],
	locale: document
		.getElementsByTagName('html')[0]
		.getAttribute('lang')
		.substring(0, 2),
	locales: {
		en: {
			required({ vm }) {
				if (vm.name == 'terms') {
					return 'Please agree with the privacy statement.';
				}

				return `Field '${vm.label}' is required.`;
			},
			matches({ vm }) {
				return `Field '${vm.label}' contains an invalid value. Make sure to enter a valid phone number.`;
			},
			phone({ vm }) {
				return `Field '${vm.label}' contains an invalid value. Make sure to enter a valid phone number.`;
			},
			email({ vm }) {
				return `“${vm.label}” is not a valid email address. A valid email address looks like this: 'name@domain.nl'.`;
			},
			after({ vm, args }) {
				if (Array.isArray(args) && args.length) {
					const date = args[0].split(/\D/g);

					return `Field '${vm.label}' must be after ${[date[2], date[1], date[0]].join('-')}.`;
				}

				return `Field '${vm.label}' must be a later date.`;
			},
			betweendates({ vm }) {
				return 'The recovery certificate you are requesting has expired or is not yet valid.';
			},
			bsn({ vm }) {
				return `Field '${vm.label}' is invalid.`;
			},
			min({ vm, value, args }) {
				if (Array.isArray(value)) {
					return `You need at least ${args[0]} ${vm.label}.`;
				}

				const force = Array.isArray(args) && args[1] ? args[1] : false;
				if ((!isNaN(value) && force !== 'length') || force === 'value') {
					return `Field '${s(vm.label)}' must be at least ${args[0]}.`;
				}

				return `Field '${s(vm.label)}' must be at least ${args[0]} characters long.`;
			},
			max({ vm, value, args }) {
				if (Array.isArray(value)) {
					return `You may only select ${args[0]} ${vm.label}.`;
				}

				const force = Array.isArray(args) && args[1] ? args[1] : false;
				if ((!isNaN(value) && force !== 'length') || force === 'value') {
					return `Field '${vm.label}' must be less than or equal to ${args[0]}.`;
				}

				return `Field '${vm.label}' must be less than or equal to ${args[0]} characters long.`;
			},
			date({ vm, args }) {
				if (Array.isArray(args) && args.length) {
					return `Field '${vm.label}' is not a valid date, please use the format DD-MM-YYYY. Do you use Safari on Mac? Then the correct format is YYYY-MM-DD.`;
				}
				return `Field '${vm.label}' is not a valid date.`;
			},
		},
		nl: {
			required({ vm }) {
				if (vm.name == 'terms') {
					return 'Ga akkoord met de privacyverklaring.';
				}

				return `Veld '${vm.label}' is verplicht.`;
			},
			matches({ vm }) {
				return `Veld '${vm.label}' bevat een ongeldige waarde. Zorg ervoor dat u een geldig telefoonnummer invoert.`;
			},
			phone({ vm }) {
				return `Veld '${vm.label}' bevat een ongeldige waarde. Zorg ervoor dat u een geldig telefoonnummer invoert.`;
			},
			email({ vm }) {
				return `“${vm.label}” is geen geldig e-mailadres. Een geldig e-mailadres ziet er als volgt uit: 'name@domain.nl'.`;
			},
			after({ vm, args }) {
				if (Array.isArray(args) && args.length) {
					const date = args[0].split(/\D/g);

					return `Veld '${vm.label}' moet na ${[date[2], date[1], date[0]].join('-')} zijn.`;
				}

				return `Veld '${vm.label}' moet een latere datum zijn.`;
			},
			betweendates({ vm }) {
				return 'Het door u opgevraagde herstelbewijs heeft zijn geldigheid verloren of is nog niet geldig.';
			},
			bsn({ vm }) {
				return `Veld '${vm.label}' is niet geldig.`;
			},
			min({ vm, value, args }) {
				if (Array.isArray(value)) {
					return `Je moet tenminste ${args[0]} selecteren als ${vm.label}.`;
				}

				const force = Array.isArray(args) && args[1] ? args[1] : false;
				if ((!isNaN(value) && force !== 'length') || force === 'value') {
					return `Veld '${vm.label}' moet groter zijn dan ${args[0]}.`;
				}

				return `Veld '${vm.label}' moet tenminste ${args[0]} karakters bevatten.`;
			},
			max({ vm, value, args }) {
				if (Array.isArray(value)) {
					return `Je kunt maximaal ${args[0]} selecteren als ${vm.label}.`;
				}

				const force = Array.isArray(args) && args[1] ? args[1] : false;
				if ((!isNaN(value) && force !== 'length') || force === 'value') {
					return `Veld '${vm.label}' moet kleiner of gelijk zijn aan ${args[0]}.`;
				}

				return `Veld '${vm.label}' mag maximaal ${args[0]} karakters bevatten.`;
			},
			date({ vm, args }) {
				if (Array.isArray(args) && args.length) {
					return `Veld '${vm.label}' is geen geldige datum, het juiste formaat is DD-MM-YYYY. Gebruik je Safari op Mac? Dan is het juiste formaat YYYY-MM-DD.`;
				}
				return `Veld '${vm.label}' is geen geldige datum.`;
			},
		},
	},
	slotProps: {
		label: ['variation'],
	},
	slotComponents: {
		// Use the string name of the globally registered component.
		// button: 'FormButton',
		errors: 'FormErrors',
		help: 'FormHelp',
		label: 'FormLabel',
	},
	library: {
		button: {
			component: 'FormButton',
		},
		telephone: {
			classification: 'text',
			component: 'FormInputTelephone',
		},
	},
	uploader: function(file, progress) {
		// optionally handle the `file` for your own purposes here...
		progress(100);
		return Promise.resolve({});
	},
});

Vue.use(VueAxios, axios);

Vue.config.productionTip = false;

if (document.getElementById('contact-form')) {
	new Vue({
		render: h => h(Form),
	}).$mount('#contact-form');
}

if (document.getElementById('contact-form-objection')) {
	new Vue({
		render: h => h(FormObjection),
	}).$mount('#contact-form-objection');
}
