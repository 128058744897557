<template>
	<div :class="context.classes.element" :data-type="context.type">
		<vue-tel-input
			v-model="context.model"
			v-bind="context.attributes"
			@blur="context.blurHandler"
			@validate="validate"
			:inputOptions="inputOptions"
			mode="international"
			:validCharactersOnly="true"
			defaultCountry="NL"
			:allCountries="allCountries"
		>
			<template slot="arrow-icon">&nbsp;</template>
		</vue-tel-input>
	</div>
</template>

<script>
import { VueTelInput } from 'vue-tel-input';
import allCountries from './../helpers/countries.js';

export default {
	name: 'FormInputTelephone',
	components: {
		VueTelInput,
	},
	props: {
		context: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			allCountries: allCountries,
			inputOptions: {
				autocomplete: 'on',
				autofocus: false,
				'aria-describedby': '',
				id: '',
				maxlength: 25,
				name: 'phone',
				placeholder: '',
				readonly: false,
				required: true,
				tabindex: 0,
				type: 'tel',
				styleClasses: '',
			},
		};
	},
	watch: {},
	computed: {
		model() {
			return this.context.model;
		},
	},
	created() {
		this.$formulate.validPhoneNumber = {
			...this.$formulate.validPhoneNumber,
			[this.context.name]: false,
		};
	},
	methods: {
		validate(obj) {
			const valid = typeof obj.valid === 'undefined' ? false : obj.valid;
			this.$formulate.validPhoneNumber = {
				...this.$formulate.validPhoneNumber,
				[this.context.name]: valid,
			};
		},
	},
};
</script>
../.helpers/countries.js